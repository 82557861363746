import { SxProps } from '@mui/system';

import background from '../../assets/images/background.png';

export const mainStyle: SxProps = {
  height: { 
    xs: '80rem',
    sm: '100rem',    
    lg: '100rem', 
  },  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',      
  alignItems: 'center',
  backgroundImage: `url(${background})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  position: 'relative',
};

export const avatarStyle: SxProps ={
  width: {
    xs: '20rem',
    sm: '25rem',
  },
  height: {
    xs: '20rem',
    sm: '25rem',
  },
  position: {
    lg: 'absolute' as 'absolute',
  },
  top: {
    xs: '3rem',     
  },
  left: {
    xs: '10rem',      
  },     
};

export const titleStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '4rem',  
};

export const h1Style: SxProps = {
  fontSize: {
    xs: '4rem',
    sm: '6rem',
    lg: '8rem',
    xl: '10rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  textAlign: 'center',
  color: 'var(--color-text-base)',
  textShadow: {
    xs: '1px 1px var(--color-text-base-shadow)',
    sm: '2px 2px var(--color-text-base-shadow)',
    lg: '2.5px 2.5px var(--color-text-base-shadow)',
  },
  marginTop: {
    xs: '1rem',
  },
  position: {
    lg: 'absolute' as 'absolute',
  },
  top: {
    lg: '21rem',
    xl: '20rem',    
  },
  left: {
    lg: '25rem',      
  },  
};

export const h2Style: SxProps = {
  fontSize: {
    xs: '2.2rem',
    sm: '2.8rem',
    lg: '3rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  textAlign: 'center',
  color: 'var(--color-text-base)',
  marginTop: {
    xs: '1rem',
  }, 
  position: {
    lg: 'absolute' as 'absolute',
  },
  top: {
    lg: '15rem',     
  },
  left: {
    lg: '37rem',      
  }, 
};

export const videoBoxStyle: SxProps = {
  position: 'relative',
  //paddingTop: '56.25%',
  width: {
    xs: '35rem',
    sm: '50rem',
    md: '65rem',
    lg: '85rem',
  },
  height: {
    xs: '19.77rem',
    sm: '28.24rem',
    md: '36.72rem',
    lg: '48.02rem',    
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',
  marginTop: {
    xs: '10rem',
    lg: '40rem',
  },
};

export const imgStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

export const imgBoxStyle: SxProps = {
  position: 'relative',
 
  width: {
    xs: '35rem',
    sm: '50rem',
    md: '65rem',
    lg: '85rem',
  },
  height: {
    xs: '19.77rem',
    sm: '28.24rem',
    md: '36.72rem',
    lg: '48.02rem',    
  },
  marginTop: {
    xs: '10rem',
    lg: '40rem',
  },
};

export const reactPlayer: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,  
};
