import React, { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import ReactPlayer from 'react-player'

import api from '../../services/api';

import jaime from '../../assets/images/foto.png';
import thumbnail from '../../assets/images/thumbnail.png';

import {
  mainStyle,
  avatarStyle, 
  titleStyle,
  h1Style,
  h2Style,  
  reactPlayer,
  imgStyle,
  imgBoxStyle,
  videoBoxStyle,
} from './styles';

const Main: React.FC = () => {
  const [ url, setUrl ] = useState<any>(null);  

  useEffect(() => {  
    async function loadData() {
      try {
        const payload = {
          filename: 'apoio/brigando_com_deus.mp4',
        }
        const response = await api.post('/public/getSignedUrl', payload);         
        if (response.data) {          
          setUrl(response.data.url);          
        }        
      } catch (error: any) {
                          
      }     
    }    
    loadData();    
  }, []);  

  return (
    <Box sx={mainStyle}>
      <Box sx={titleStyle}>
        <Box component='img' sx={avatarStyle} src={jaime} alt='Jaime'/>
        <Box component='h2' sx={h2Style}>JAIME BENEDETTI EM...</Box>
        <Box component='h1' sx={h1Style}>BRIGANDO COM DEUS</Box>
        {
          url ? 
            <Box sx={videoBoxStyle}>
              <ReactPlayer
                style={reactPlayer}
                url={url}
                width='100%'
                height='100%'
                controls
                pip
                light={<img style={imgStyle} src={thumbnail} alt='Thumbnail' />}            
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload'
                    }
                  }
                }}
              />           
            </Box>
          :
            <Box component='img' sx={imgBoxStyle} src={thumbnail} alt='Thumbnail' />
        }
      </Box>
    </Box>    
  );
};

export default Main;
